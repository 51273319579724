import { useState } from 'react'
import { NavLink } from 'react-router-dom'
//import {ReactComponent as Brand} from '../../logo.svg'
import { ReactComponent as Hamburger } from '../../icons/hamburger.svg'
import './Navbar.css'

const Navbar = () => {

    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }


    return (
        <nav className="navbar">
            <div className="container">

                <div className="logo">
                   
                </div>            

                <div className="menu-icon" onClick={handleShowNavbar}>
                    <Hamburger />
                </div>

                <div className={`nav-elements ${showNavbar && 'active'}`}>
                    <ul>
                        <li>
                            <NavLink to="#home">home</NavLink>
                        </li>
                        <li>
                            <NavLink to="#portoflio">portóflio</NavLink>
                        </li>
                        <li>
                            <NavLink to="#servicos">serviços</NavLink>
                        </li>
                        <li>
                            <NavLink to="#contato">contato</NavLink>
                        </li>
                    </ul>                
                </div>
            </div>      
        </nav>
    )
}

export default Navbar
