import logo from './logo.svg';
import './App.css';
import Banner from './componentes/Banner/Banner';

import { BrowserRouter } from 'react-router-dom';
import Navbar from './componentes/Navbar/Navbar';
import Carousel from './componentes/Carousel/Carousel';

import ReactGA from 'react-ga';
import About from './componentes/About/About';
const TRACKING_ID = "G-TBF00HEZHY";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Navbar />
      </BrowserRouter>

      <Banner />

      <Carousel />

     
      <About />
    </div>
  );
}

export default App;
