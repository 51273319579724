import "./About.css"

const About = () => {
    return (
        <div className="about">
            <h1>Sobre nós</h1>
            <div className="tamanho-paragrafo">
                <p>A satisfação de trabalhar com realismo e ver a reação
                dos clientes com os resultados é o que nos motiva e
                move a buscar a perfeição em cada detalhe.</p>
                <p>Aqui ajudamos a valorizar o trabalho do nosso cliente
                proporcionando aos clientes deles experiências
                únicas em forma de imagens e vídeos.</p>
            </div>
        </div>
    )
}


export default About