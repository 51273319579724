import './Banner.css'

function Banner () {
    return (
        <header class="banner">
            <img src="/imagens/banner.jpg" alt="banner"/>
        </header>
    )
}

export default Banner