import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Carousel.css"

const images = [
    {
        original: "https://cdn.arthouserender.com.br/comprimidas/foto (16).jpg",
        thumbnail: "https://cdn.arthouserender.com.br/comprimidas/foto (16).jpg",
    },
    {
        original: "https://cdn.arthouserender.com.br/comprimidas/foto (13).jpg",
        thumbnail: "https://cdn.arthouserender.com.br/comprimidas/foto (13).jpg",
    },
    {
        original: "https://cdn.arthouserender.com.br/comprimidas/foto (8).jpg",
        thumbnail: "https://cdn.arthouserender.com.br/comprimidas/foto (8).jpg",
    },
    {
        original: "https://cdn.arthouserender.com.br/comprimidas/foto (9).jpg",
        thumbnail: "https://cdn.arthouserender.com.br/comprimidas/foto (9).jpg",
    },
    {
        original: "https://cdn.arthouserender.com.br/comprimidas/foto (1).jpg",
        thumbnail: "https://cdn.arthouserender.com.br/comprimidas/foto (1).jpg",
    }
]

const Carousel = () => {
    return (
        <div className="carousel">
            <ImageGallery items={images} />
        </div>
    )
}

export default Carousel